
import { Component, Vue, Watch } from 'vue-property-decorator'
import BusifyLogo from '@/components/BusifyLogo.vue'
import { validateRules, validationRules } from '@/utils/rules'
import user from '@/services/user'

@Component({ components: { BusifyLogo } })
export default class ForgotPassword extends Vue {
  email = ''
  success = false
  isSubmitting = false
  isSubmitted = false
  rules = validationRules

  @Watch('email')
  emailChanged(): void {
    this.isSubmitted = false
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }
    this.isSubmitting = true
    try {
      const res = await user.forgotPassword(this.email)
      if (res.data.successful) {
        this.success = true
      }
    } catch (error) {
      this.success = false
    }
    this.isSubmitting = false
    this.isSubmitted = true
  }
}
