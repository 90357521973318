var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex h-full"},[_c('div',{staticClass:"h-full w-three-fifths flex-grow-1"},[_c('div',{staticClass:"max-w-584 h-full margin-r-auto",class:{
        'padding-t-10 padding-x-8': _vm.$vuetify.breakpoint.width >= 900,
        'padding-t-8 padding-x-5': _vm.$vuetify.breakpoint.width < 900,
      },style:(_vm.$vuetify.breakpoint.width > 900
          ? { marginLeft: 'calc(0.377*100vw - 346px)' }
          : { marginLeft: 'auto' })},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('a',{attrs:{"href":"https://www.busify.com"}},[_c('BusifyLogo',{staticClass:"w-128"})],1),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_c('v-btn',{attrs:{"x-small":"","text":"","color":"primary"}},[_c('p',{staticClass:"font-16 font-bold text-primary"},[_vm._v("Log In")])])],1)],1),_c('div',{staticClass:"position-relative",style:({ top: 'calc(50vh - 70px)', transform: 'translateY(-45%)' })},[_c('h3',{staticClass:"margin-b-2",style:({ textAlign: 'center' })},[_vm._v(" Forgot password? ")]),_c('p',{staticClass:"font-16 margin-b-6",style:({ textAlign: 'center' })},[_vm._v(" Enter the email address associated with your account, and we'll email you a link to reset your password. ")]),_c('v-form',{ref:"form",staticClass:"padding-y-8 background-white border-radius-30 box-shadow-heavy margin-b-15",class:{
            'padding-x-8': _vm.$vuetify.breakpoint.width >= 450,
            'padding-x-4': _vm.$vuetify.breakpoint.width < 450,
          }},[_c('CUTextField',{staticClass:"margin-b-5",attrs:{"id":"forgot-password-email","type":"email","height":"52px","label":_vm.email && 'Email',"placeholder":"Email","rules":_vm.rules.email('Email is required')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.success && _vm.email && _vm.isSubmitted)?_c('div',{staticClass:"w-full d-flex justify-center margin-t-n7 margin-b-2"},[_c('p',{staticClass:"text-success"},[_vm._v(" Your password reset email is on the way! ")])]):(!_vm.success && _vm.email && _vm.isSubmitted)?_c('div',{staticClass:"w-full d-flex justify-center margin-t-n7 margin-b-2"},[_c('p',{staticClass:"text-error"},[_vm._v(" This email is not associated with a valid account. ")])]):_vm._e(),_c('CUButton',{staticClass:"w-full font-16",attrs:{"unset-width":"","height":"52px","loading":_vm.isSubmitting},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1),_c('p',{staticClass:"font-16",style:({ textAlign: 'center' })},[_vm._v(" If you haven't signed up with Busify, visit "),_c('a',{staticClass:"text-secondary",attrs:{"href":"https://www.busify.com"}},[_vm._v(" busify.com ")]),_vm._v(" to book a demo. ")])],1)])]),_c('div',{staticClass:"align-center max-w-two-fifths h-full background-primary-10",class:{
      'd-flex': _vm.$vuetify.breakpoint.width >= 900,
      'd-none': _vm.$vuetify.breakpoint.width < 900,
    }},[_c('div',{style:({ height: '90vh', overflow: 'hidden' })},[_c('img',{style:({ height: '100%' }),attrs:{"src":require("@/assets/images/LoginImage.webp")}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }